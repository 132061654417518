/** @jsx jsx */
import { jsx } from "theme-ui"
import styled, { keyframes } from "styled-components"
import { graphql } from "gatsby"
import { Styled } from "theme-ui"

import { rem } from "@src/theme"

import Layout from "@components/Layouts"
import Section from "@components/Section"
import SEO from "@components/seo"
import TextCarousel from "@components/TextCarousel"
import TestimonialCarousel from "@components/TestimonialCarousel"
import TwoColumnSection, {
  Block as ColumnBlock,
} from "@components/TwoColumnSection"
import { DonateSection } from "@components/ImpactFooter"
import CTAHeadingBlock from "@components/CTAHeadingBlock"
import PartnersSection from "@components/PartnersSection"
import ImageTextBlocksList from "@components/ImageTextBlocksList"
import AccordionWithBanner from "@components/Accordion/WithBanner"
import Spacer from "@components/Spacers"
import { Themed } from "@theme-ui/mdx"

const About = ({ data, ...props }) => {
  const {
    headerImage,
    imageTextBlocks,
    carouselStats,
    carouselTestimonials,
    accordion,
    partnersLogo,
    ogdescription,
  } = data.markdownRemark.frontmatter

  return (
    <Layout>
      <SEO
        title="About"
        pathname={props.location.pathname}
        image={headerImage}
        description={ogdescription}
      />
      <TwoColumnSection
        col1={
          <ColumnBlock
            styles={{
              bg: "purple",
              color: "pink",
            }}
          >
            <CTAHeadingBlock heading="CHAMPIONS FOR LGBTQIA+ YOUTH" />
          </ColumnBlock>
        }
        col2={
          <ColumnBlock
            styles={{
              bg: "turquoise",
              color: "darkPurple",
            }}
          >
            <CTAHeadingBlock
              heading="CHANGING THE LIVES OF LGBTQIA+ YOUTH IN AUSTRALIA"
              body="We’re leading change, building social inclusion, and advocating for an Australia where all young people are safe, empowered, and surrounded by people that support them."
              smallHeading
              widthLimit
            />
          </ColumnBlock>
        }
      />

      <div
        sx={{
          backgroundImage: `url(${headerImage})`,
          minHeight: [rem(420), null, rem(600)],
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
        }}
      ></div>

      <BannerWrapper
        sx={{
          overflow: "hidden",
        }}
      >
        <Themed.h2 sx={{ whiteSpace: "nowrap" }} className="banner-text">
          You are loved you are loved you are loved you are loved you are loved
          you are loved you are loved you are loved you are loved you are loved
          you are loved you are loved you are loved you are loved you are loved
          you are loved you are loved you are loved you are loved you are loved
          you are loved
        </Themed.h2>
      </BannerWrapper>
      <GradientWrapper>
        <ImageTextBlocksList blocks={imageTextBlocks} />

        <Section>
          <TextCarousel blocks={carouselStats} />
        </Section>
        <Spacer.Large />
      </GradientWrapper>

      <AccordionWithBanner
        items={accordion.accordionItems}
        title="Our work"
        noMaxWidthBanner
      />

      <Section
        sx={{
          bg: "pink",
        }}
      >
        <TestimonialCarousel testimonials={carouselTestimonials} />
      </Section>
      <PartnersSection img={partnersLogo} />

      <DonateSection />
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        headerImage
        accordion {
          ...Accordion
        }
        imageTextBlocks {
          ...ImageTextBlocks
        }
        carouselStats {
          ...CarouselStats
        }
        carouselTestimonials {
          ...CarouselTestimonial
        }
        partnersLogo
        ogdescription
      }
    }
  }
`

const GradientWrapper = styled.div`
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(221, 243, 255, 0) 0.01%,
      rgba(14, 125, 185, 0.5) 100%
    ),
    #ddf3ff;
`

const bannermove = keyframes`
  0% {
     margin-left: 0px;
  }
  100% {
     margin-left: -5746px;
  }
 
`

const BannerWrapper = styled.div`
  .banner-text {
    -webkit-animation: ${bannermove} 50s linear infinite;
    -moz-animation: ${bannermove} 50s linear infinite;
    -ms-animation: ${bannermove} 50s linear infinite;
    -o-animation: ${bannermove} 50s linear infinite;
    animation: ${bannermove} 50s linear infinite;
  }
`
